import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const Footer = styled.footer`
  @media ${breakpoints.mobile} {
    position: relative;
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #ffffff;
    padding: 0px 16px 20px;

    &:after {
      content: '';
      position: absolute;
      width: 100vw;
      height: 1px;
      border-top: 1px solid #d8d8d8;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media ${breakpoints.tablet} {
    padding: 80px 24px 20px;
  }

  @media ${breakpoints.laptop} {
    padding: 80px 36px 20px;
  }

  @media ${breakpoints.desktop} {
    padding: 80px 50px 20px;
  }
`;

export const Socials = styled.div`
  @media ${breakpoints.mobile} {
    & {
      margin-top: 49px;
    }

    .social-link {
      margin-right: 20px;
    }

    .icon {
      cursor: pointer;
      width: 20px;
      height: 20px;

      & path {
        fill: #222222;
      }

      &:active path {
        fill: #049077;
      }

      &:hover path {
        fill: #07a186;
      }
    }
  }

  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 31%;
      max-width: 270px;
      margin: 0 0 0 auto;
    }

    .social-link:last-child {
      margin-right: 0;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      width: 270px;
    }
  }
`;

export const Box = styled.div`
  @media ${breakpoints.laptop} {
    width: calc(23% - 30px);
    margin-right: 30px;
  }

  @media ${breakpoints.desktop} {
    width: ${({ id }) => (id === 'car-owners' ? '251px' : '228px')};
    margin-right: 60px;
  }
`;

export const Blog = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    background: #222222;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    border-radius: 12px;
    padding: 4px 12px;

    &:hover {
      background: #07a186;
    }
  }
`;

export const Messengers = styled.div`
  @media ${breakpoints.mobile} {
    margin-bottom: 24px;
    display: flex;
  }

  @media ${breakpoints.laptop} {
    width: 100%;
  }
`;

export const Title = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 18px;
    width: 100%;
  }
`;

export const List = styled.ul`
  @media ${breakpoints.mobile} {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }

  @media ${breakpoints.laptop} {
    flex-wrap: wrap;
    margin-top: 24px;
  }
`;

export const TextLink = styled.a`
  @media ${breakpoints.mobile} {
    &:hover {
      color: #066bd6;
    }
  }
`;

export const Item = styled.li`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: ${(props) => props.fontSize || '14px'};
    line-height: 160%;
    color: #5a5a5a;
    margin-bottom: 16px;
  }

  @media ${breakpoints.mobile} {
    line-height: 140%;
    margin-bottom: 9px;
  }
`;

export const Contact = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
    margin-bottom: 37px;

    &:first-child {
      margin-bottom: 4px;
    }
  }

  @media ${breakpoints.laptop} {
    width: auto;
    height: 26px;

    &:first-child {
      margin-bottom: 25px;
    }

    &:nth-child(3) {
      margin-top: 24px;
    }

    &:last-child {
      margin-top: 30px;
    }
  }
`;

export const Copyrights = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
    color: #5a5a5a;
  }

  @media ${breakpoints.laptop} {
    margin-top: 0;
    margin-left: 10px;
  }
`;

export const LastBox = styled.div`
  @media ${breakpoints.mobile} {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    padding-top: 8px;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      border-top: 1px solid #d8d8d8;
      top: 0;
    }
  }

  @media ${breakpoints.laptop} {
    flex-direction: row;
    justify-content: space-between;

    &:after {
      width: 100vw;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const TextBlock = styled.span`
  @media ${breakpoints.mobile} {
    display: block;
    font-size: 10px;
    margin-bottom: 8px;
  }

  @media ${breakpoints.mobile} {
    width: calc(100% - 170px);
    max-width: 1073px;
  }
`;

export const FooterContainer = styled.div`
  @media ${breakpoints.laptop} {
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      margin-bottom: 130px;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: flex;

  @media ${breakpoints.mobile} {
    margin-bottom: 30px;
  }
`;

export const ImgContainer = styled.div`
  margin-right: 10px;
`;
