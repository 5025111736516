import React, { useState } from 'react';
import { Link } from 'gatsby';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import ReferalPopup from './popup';
// import ContactMenu from '../contactMenu';
import { DesktopNav } from './DesktopNav/DesktopNav';
import { MobileNav } from './MobileNav/MobileNav';
import LogoTextIcon from '../../assets/icons/logo-text.svg';
import LogoIcon from '../../assets/icons/logo.svg';

import { Header, Content } from './styles';

const CommonHeader = () => {
  const [open, setOpen] = useState(false);

  const isLaptop = useMediaQuery('(max-width:1023px)');
  const isMoreThenMobile = useMediaQuery('(min-width:1024px)');

  const handleClose = () => setOpen(false);

  const handleClickOpen = () => setOpen(true);

  return (
    <Header className="header">
      <Content>
        <Link to="/" title="На главную" className="link">
          {isLaptop && <LogoIcon className="logo" />}
          {isMoreThenMobile && <LogoTextIcon className="logo" />}
        </Link>
        <Toolbar className="toolbar">
          {isLaptop && <MobileNav openReferalPopup={handleClickOpen} />}
          {isMoreThenMobile && <DesktopNav openReferalPopup={handleClickOpen} />}
        </Toolbar>
        <Dialog
          scroll="body"
          maxWidth={false}
          fullScreen={isLaptop}
          open={open}
          onClose={handleClose}
        >
          <ReferalPopup onClose={handleClose} />
        </Dialog>
      </Content>
    </Header>
  );
};

export default CommonHeader;
