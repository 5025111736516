export const footerContent = [
  {
    title: 'Getarent',
    id: 'Getarent',
    list: [
      {
        key: 'Service-rules',
        link: 'https://help.getarent.ru',
        name: 'Служба поддержки',
        target: '_target',
      },
      {
        key: 'Personal-data-processing-policy',
        link: 'https://storage.yandexcloud.net/getarent-documents-bucket/3208ff5d2ed1a6a3.pdf',
        name: 'Пользовательское соглашение',
        target: '_target',
      },
      {
        key: 'Terms-of-use',
        link: 'https://storage.yandexcloud.net/getarent-documents-bucket/c6364667735bbdaf.pdf',
        name: 'Политика обработки персональных данных',
        target: '_target',
      },
      {
        key: 'Consent-to-the-processing-of-personal-data',
        link: 'https://storage.yandexcloud.net/getarent-documents-bucket/C_.pdf',
        name: 'Согласие на обработку персональных данных',
        target: '_target',
      },
      // {
      //   key: 'Agency-contract',
      //   link: 'https://storage.yandexcloud.net/getarent-documents-bucket/44c8b0961f3fa6b1.pdf',
      //   name: 'Агентский договор',
      //   target: '_target',
      // },
      // {
      //   key: 'Lease-contract',
      //   link: 'https://storage.yandexcloud.net/getarent-documents-bucket/4a3531f263936f16.pdf',
      //   name: 'Договор аренды',
      //   target: '_target',
      // },
    ],
  },
  {
    title: 'Водителям - арендаторам',
    id: 'car-owners',
    list: [
      {
        key: 'age-and-experience',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/26-trebovaniya-k-voditelyu-i-pravila-polzovaniya-avtomobilem',
        name: 'Возраст и стаж водителя',
        target: '_target',
      },
      {
        key: 'Payment-of-rent',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/66-oplata-poezdki?utm_source=podval-gost&utm_medium=oplata-arendi',
        name: 'Оплата аренды',
        target: '_target',
      },
      {
        key: 'Free-rental-cancellation',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/64-otmena-arendyi?utm_source=podval-gost&utm_medium=otmena-arendi',
        name: 'Бесплатная отмена аренды',
        target: '_target',
      },
      {
        key: 'Car-delivery-and-return',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/37-dostavka-i-vozvrat-avtomobilya?utm_source=podval-gost&utm_medium=dostavka-vozvrat',
        name: 'Доставка и возврат автомобиля',
        target: '_target',
      },
      {
        key: 'Additional-services-and-devices',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/60-dopolnitelnyie-uslugi-i-oborudovanie?utm_source=podval-gost&utm_medium=uslugi-ustroistva',
        name: 'Дополнительные услуги и устройства',
        target: '_target',
      },
      {
        key: 'Traffic-police-fines',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/68-polnoe-rukovodstvo-po-uscherbam?&utm_medium=oplata',
        name: 'Штрафы ГИБДД, оплата за топливо, мойку и другое',
        target: '_target',
      },
      {
        key: 'Accidents-and-road-accidents',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/75-ya-popal-v-avariyu-povredil-avtomobil',
        name: 'Аварии и ДТП',
        target: '_target',
      },
      // {
      //   key: 'rent',
      //   link: 'car-rental-income',
      //   name: 'Поделиться автомобилем',
      //   target: '_self',
      // },
      // {
      //   key: 'cars-requirements',
      //   link:
      //     'https://help.getarent.ru/ru/knowledge-bases/2/articles/52-trebovaniya-k-avtomobilyam?utm_source=podval-host&utm_medium=trebovanie-avto',
      //   name: 'Требования к машинам',
      //   target: '_target',
      // },
      // {
      //   key: 'Service-requirements',
      //   link:
      //     'https://help.getarent.ru/ru/knowledge-bases/2/articles/53-trebovaniya-k-obsluzhivaniyu?utm_source=podval-host&utm_medium=trebovanie-obslujivanie',
      //   name: 'Требования по обслуживанию',
      //   target: '_target',
      // },
      // {
      //   key: 'Rent-payments',
      //   link:
      //     'https://help.getarent.ru/ru/knowledge-bases/2/articles/22-vyiplata-za-arendu?utm_source=podval-host&utm_medium=viplota-arendu',
      //   name: 'Выплаты за аренду',
      //   target: '_target',
      // },
      // {
      //   key: 'Compensation-for-damage',
      //   link:
      //     'https://help.getarent.ru/ru/knowledge-bases/2/articles/48-zapros-na-vozmeschenie-uscherba?utm_source=podval-host&utm_medium=vozmeschenie-uscherba',
      //   name: 'Возмещение ущерба',
      //   target: '_target',
      // },
    ],
  },
  {
    title: 'Партнёрам',
    id: 'guests',
    list: [
      {
        key: 'How-to-use',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/23-polnoe-rukovodstvo-dlya-vladeltsa',
        name: 'Как начать сдавать автомобили',
        target: '_target',
      },
      {
        key: 'Help',
        link:
          'https://help.getarent.ru/knowledge-bases/2-tsentr-pomoschi-getarent/categories/1-pomosch-vladeltsu-avtomobilya',
        name: 'Помощь',
        target: '_target',
      },
      {
        key: 'Responsibilities',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/48-zapros-na-vozmeschenie-uscherba',
        name: 'За что отвечает Getarent?',
        target: '_target',
      },
      {
        key: 'Cars-acception',
        link:
          'https://help.getarent.ru/ru/knowledge-bases/2/articles/52-trebovaniya-k-avtomobilyam',
        name: 'Какие машины мы принимаем?',
        target: '_target',
      },
      // {
      //   key: 'search',
      //   link: '/app/search',
      //   name: 'Найти автомобиль',
      //   target: '_self',
      // },
      // {
      //   key: 'Driver-age-and-experience',
      //   link:
      //     'https://help.getarent.ru/ru/knowledge-bases/2/articles/26-trebovaniya-k-voditelyu-i-pravila-polzovaniya-avtomobilem?utm_source=podval-gost&utm_medium=vozrasr-staj',
      //   name: 'Возраст и стаж водителя',
      //   target: '_target',
      // },
    ],
  },
];
