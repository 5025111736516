import React, { useCallback, useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';

import AppLinksBlock from '../../ui-kit/appLinks';
import { userMobileNavigationSchema } from '../navigationSchema';
import { checkHost, checkObserver, getUserInfo } from '../../../services/auth-service';
import { signOutHandler } from '../signOutHandler';
import UserIcon from '../../../assets/icons/user-logo.svg';
import LogoIcon from '../../../assets/icons/logo.svg';
import QuestionMark from '../../../assets/icons/question-mark.svg';

import { InsuranceIngostrah } from '../../../styledComponents';
import { MobileNavWrapper, SideBarMenu, SmallText } from './styles';
import { MobileAppAttemptDialog } from '../../mobileAppAttemptDialog';

export const MobileNav = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [isObserver, setIsObserver] = useState(false);
  const [isMobileBlocking, setIsMobileBlocking] = useState(false);

  useEffect(() => {
    setIsLoggedIn(getUserInfo());
    setIsHost(checkHost());
    setIsObserver(checkObserver());
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.querySelector('html').style.overflow = 'hidden';
      document.body.addEventListener('pointermove', stopScroll, { passive: false });
    } else {
      document.querySelector('html').style.overflow = 'unset';
      document.body.removeEventListener('pointermove', stopScroll);
    }
  }, [isOpened]);

  const SideBarIcon = isLoggedIn ? PersonIcon : MenuIcon;

  const toggleOpen = useCallback(() => {
    setIsOpened((prevIsOpened) => !prevIsOpened);
  }, []);

  const handleClickAway = useCallback(() => {
    setIsOpened(false);
  }, []);

  const signOutClick = useCallback(async () => {
    try {
      await signOutHandler();
      toggleOpen();
      setIsLoggedIn(false);
      setIsHost(false);
      setIsObserver(false);
    } catch (e) {
      throw new Error(e);
    }
  }, [toggleOpen]);

  const stopScroll = (e) => {
    e.preventDefault();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MobileNavWrapper>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            // if (!isOpened) {
            //   track([yandexTracks.headerShowMore]);
            // }
            toggleOpen();
          }}
          className="icon-wrap"
        >
          {isOpened ? <CloseIcon className="menu-icon" /> : <SideBarIcon className="menu-icon" />}
        </IconButton>
        <SideBarMenu
          variant="persistent"
          anchor="right"
          open={isOpened}
          PaperProps={{ className: 'menu' }}
          onTouchMove={(e) => e.preventDefault()}
        >
          <LogoIcon className="menu-logo" />
          <List className="list">
            {/* {isLoggedIn ? (
              <ListItem button className="link -logout" onClick={signOutClick}>
                <UserIcon className="user-icon" />
                Выйти
              </ListItem>
            ) : (
              <ListItem button className="link -login">
                <UserIcon className="user-icon" />
                <span onClick={() => setIsMobileBlocking(true)} className="sub-link">
                  Вход
                </span>
                <span className="separator">или</span>
                <span onClick={() => setIsMobileBlocking(true)} className="sub-link">
                  регистрация
                </span>
              </ListItem>
            )} */}
            {/* <Divider className="divider -first" /> */}
            {userMobileNavigationSchema.map(({ title, key, path, seo }) => {
              if (key === 'my-rents-host-gost') {
                return (
                  isLoggedIn &&
                  !isObserver && (
                    <a key={key} href={path} title={title} className="link">
                      <ListItem button onClick={toggleOpen} className="item">
                        {title}
                      </ListItem>
                    </a>
                  )
                );
              }
              if (key === 'my-cars') {
                return (
                  isHost && (
                    <a key={key} href={path} title={title} className="link">
                      <ListItem button key={key} onClick={toggleOpen} className="item">
                        {title}
                      </ListItem>
                    </a>
                  )
                );
              }
              if (key === 'account') {
                return (
                  isLoggedIn && (
                    <a key={key} href={path} title={title} className="link">
                      <ListItem button onClick={toggleOpen} className="item">
                        {title}
                      </ListItem>
                    </a>
                  )
                );
              }
              if (key === 'my-rents-guest') {
                return (
                  !isLoggedIn && (
                    <span
                      onClick={() => setIsMobileBlocking(true)}
                      key={key}
                      title={title}
                      className="link"
                    >
                      <ListItem button onClick={toggleOpen} className="item">
                        {title}
                      </ListItem>
                    </span>
                  )
                );
              }
              if (key === 'help') {
                return (
                  <a key={key} href={path} title={title} className="link item">
                    <div className="flex-wrapper">
                      <QuestionMark />
                      {title}
                    </div>
                  </a>
                );
              }
              return (
                <a key={key} href={path} title={title} className="link">
                  <ListItem
                    button
                    key={key}
                    onClick={() => {
                      // track(seo);
                      toggleOpen();
                    }}
                    className="item"
                  >
                    {title}
                  </ListItem>
                </a>
              );
            })}
            <a href="tel:8-800-350-77-80" title="8-800-350-77-80" className="link">
              <ListItem button onClick={toggleOpen} className="item">
                <div className="flex-wrapper">
                  <PhoneIcon />
                  8-800-350-77-80
                </div>
              </ListItem>
            </a>
            <a
              key="telegram-channel"
              target="_blank"
              href="https://t.me/getarentbot"
              className="link"
            >
              <ListItem button onClick={toggleOpen} className="item">
                Написать в Telegram
              </ListItem>
            </a>
            <a
              key="telegram-channel"
              target="_blank"
              href="https://api.whatsapp.com/message/2PPWJ65MUVXBA1?autoload=1&app_absent=0"
              className="link"
            >
              <ListItem button onClick={toggleOpen} className="item">
                Написать в WhatsApp
              </ListItem>
            </a>
            <Divider className="divider -last" />
            <ListItem key="apps" className="item -chat">
              <AppLinksBlock />
            </ListItem>
            <ListItem key="insurance-logo" className="item -chat">
              <InsuranceIngostrah className="insurance-logo" />
              <SmallText>Страхование машин включено</SmallText>
            </ListItem>
          </List>
        </SideBarMenu>

        <MobileAppAttemptDialog
          isModalOpen={isMobileBlocking}
          onClose={() => setIsMobileBlocking(false)}
        />
      </MobileNavWrapper>
    </ClickAwayListener>
  );
};
