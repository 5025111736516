import { YANDEX_CONSTANTS, GOOGLE_CONSTANTS } from './constants';

export const yandexTracks = {
  carRegistrationHeader: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_HEADER,
  },
  carRegistrationHost1: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_HOST_1,
  },
  carRegistrationHost2: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_HOST_2,
  },
  carRegistrationGuest1: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_1,
  },
  carRegistrationGuest2: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_2,
  },
  carRegistrationGuest3: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_3,
  },
  homePageSearch: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HOMEPAGE_SEARCH,
  },
  homePageAppstore: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HOMEPAGE_APPSTORE,
  },
  homePageGoogleplay: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HOMEPAGE_GOOGLEPLAY,
  },
  headerSignup: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HEADER_SIGNUP,
  },
  headerShowMore: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HEADER_SHOW_MORE,
  },
  headerLendYourCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.HEADER_LEND_YOUR_CAR,
  },
  aboutRentCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.ABOUT_RENT_CAR,
  },
  aboutRegisterCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.ABOUT_REGISTER_CAR,
  },
  calculatorEstimate: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.CALCULATOR_ESTIMATE,
  },
  rentalIncomeCalculator: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.RENTAL_INCOME_CALCULATOR,
  },
  rentalIncomeCarRegistrationTop: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.RENTAL_INCOME_CAR_REGISTRATION_TOP,
  },
  rentalIncomeCarRegistrationBottom: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.RENTAL_INCOME_CAR_REGISTRATION_BOTTOM,
  },
  footerBlog: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_BLOG,
  },
  footerAppstore: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_APPSTORE,
  },
  footerGoogleplay: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_GOOGLEPLAY,
  },
  footerFacebook: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_FACEBOOK,
  },
  footerInstagram: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_INSTAGRAM,
  },
  footerVk: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_VK,
  },
  footerCalculator: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.FOOTER_CALCULATOR,
  },
  krasnodarFindCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_FIND_CAR,
  },
  krasnodarBookCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_BOOK_CAR,
  },
  krasnodarAllOffers: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_ALL_OFFERS,
  },
  krasnodarCategoryBudget: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_CATEGORY_BUDGET,
  },
  krasnodarCategoryMinivan: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_CATEGORY_MINIVAN,
  },
  krasnodarCategoryPremium: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_CATEGORY_PREMIUM,
  },
  krasnodarCategoryCrossover: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.KRASNODAR_CATEGORY_CROSSOVER,
  },
  moscowFindCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_FIND_CAR,
  },
  moscowBookCar: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_BOOK_CAR,
  },
  moscowAllOffers: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_ALL_OFFERS,
  },
  moscowCategoryBudget: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_CATEGORY_BUDGET,
  },
  moscowCategoryMinivan: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_CATEGORY_MINIVAN,
  },
  moscowCategoryPremium: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_CATEGORY_PREMIUM,
  },
  moscowCategoryCrossover: {
    tracker: YANDEX_CONSTANTS.TRACKER,
    action: YANDEX_CONSTANTS.ACTIONS.REACH_GOAL,
    goal: YANDEX_CONSTANTS.GOALS.MOSCOW_CATEGORY_CROSSOVER,
  },
};

export const googleTracks = {
  carRegistrationHeader: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_HEADER,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  carRegistrationHost1: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_HOST_1,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  carRegistrationHost2: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_HOST_2,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  carRegistrationGuest1: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_1,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  carRegistrationGuest2: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_2,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  carRegistrationGuest3: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CAR_REGISTRATION_GUEST_3,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOST,
    },
  },
  homePageSearch: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HOMEPAGE_SEARCH,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOMEPAGE,
    },
  },
  homePageAppstore: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HOMEPAGE_APPSTORE,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOMEPAGE,
    },
  },
  homePageGoogleplay: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HOMEPAGE_GOOGLEPLAY,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HOMEPAGE,
    },
  },
  headerSignup: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HEADER_SIGNUP,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HEADER,
    },
  },
  headerHowItWork: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HEADER_HOW_IT_WORK,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HEADER,
    },
  },
  headerLendYourCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.HEADER_LEND_YOUR_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.HEADER,
    },
  },
  aboutRentCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.ABOUT_RENT_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.ABOUT,
    },
  },
  aboutRegisterCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.ABOUT_REGISTER_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.ABOUT,
    },
  },
  calculatorEstimate: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CALCULATOR_ESTIMATE,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.CALCULATOR,
    },
  },
  rentalIncomeCalculator: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.RENTAL_INCOME_CALCULATOR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.RENTAL_INCOME,
    },
  },
  rentalIncomeCarRegistrationTop: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.RENTAL_INCOME_CAR_REGISTRATION_TOP,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.RENTAL_INCOME,
    },
  },
  rentalIncomeCarRegistrationBottom: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.RENTAL_INCOME_CAR_REGISTRATION_BOTTOM,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.RENTAL_INCOME,
    },
  },
  footerBlog: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_BLOG,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerAppstore: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_APPSTORE,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerGoogleplay: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_GOOGLEPLAY,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerFacebook: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_FACEBOOK,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerInstagram: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_INSTAGRAM,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerVk: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_VK,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  footerCalculator: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.FOOTER_CALCULATOR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.FOOTER,
    },
  },
  krasnodarFindCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_FIND_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR,
    },
  },
  krasnodarBookCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_BOOK_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR,
    },
  },
  krasnodarAllOffers: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_ALL_OFFERS,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR,
    },
  },
  krasnodarCategoryBudget: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_BUDGET,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR_CATEGORY,
    },
  },
  krasnodarCategoryMinivan: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_MINIVAN,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR_CATEGORY,
    },
  },
  krasnodarCategoryPremium: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_PREMIUM,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR_CATEGORY,
    },
  },
  krasnodarCategoryCrossover: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_CROSSOVER,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.KRASNODAR_CATEGORY,
    },
  },
  moscowFindCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_FIND_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW,
    },
  },
  moscowBookCar: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_BOOK_CAR,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW,
    },
  },
  moscowAllOffers: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_ALL_OFFERS,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW,
    },
  },
  moscowCategoryBudget: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_BUDGET,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW_CATEGORY,
    },
  },
  moscowCategoryMinivan: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_MINIVAN,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW_CATEGORY,
    },
  },
  moscowCategoryPremium: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_PREMIUM,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW_CATEGORY,
    },
  },
  moscowCategoryCrossover: {
    tracker: GOOGLE_CONSTANTS.TRACKER,
    action: GOOGLE_CONSTANTS.ACTIONS.EVENT,
    goal: GOOGLE_CONSTANTS.GOALS.CITY_CATEGORY_CROSSOVER,
    options: {
      event_category: GOOGLE_CONSTANTS.CATEGORIES.MOSCOW_CATEGORY,
    },
  },
};
