const trackerSystems = {
  yandex: (action, goal) => {
    if (window && window.ym) {
      // eslint-disable-next-line
      window.ym(window._env_.GATSBY_YANDEX_METRIKA_ID, action, goal);
    }
  },
  google: (action, goal, options) => {
    if (window && window.gtag) {
      window.gtag(action, goal, options);
    }
  },
};

export const track = (actions) =>
  actions.forEach(({ tracker, action, goal, options }) => {
    const trackerFunction = trackerSystems[tracker];
    trackerFunction(action, goal, options);
  });
