import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { breakpoints } from '../../../constants/breakpoints';

export const MobileNavWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
    }

    .icon-wrap {
      position: fixed;
      top: 7px;
      right: 3%;

      &:hover {
        background: none;
      }
    }
  }
`;

export const SideBarMenu = styled(Drawer)`
  & {
    min-height: 0;
  }

  .menu-logo {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 24px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 85px 0 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  .link {
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 16px;
    color: #303133;

    &:hover {
      color: #066bd6;
      font-weight: 600;
    }

    &.-logout {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 16px;
      color: #303133;
      padding: 12px 0;
    }

    &.-login {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 16px;
      padding: 12px 0;
    }

    &.-login > .sub-link {
      border-bottom: 1px dotted #303133;
    }

    &.-login > .separator {
      margin: 0 5px;
    }

    &.-login:hover {
      background: none;
    }
  }

  .flex-wrapper {
    display: flex;
    align-items: center;

    & > svg {
      padding-right: 5px;
    }
  }

  .-chat button {
    font-size: 20px;
  }

  .user-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  .item {
    display: flex;
    flex-direction: column;
    padding: 12px 0;

    &:hover {
      background: none;
    }
  }

  .item.-big {
    font-size: 20px;
  }

  .list {
    padding: 0;
  }

  .divider.-first {
    margin: 18px 0 23px;
  }

  .divider.-last {
    margin: 23px 0 18px;
  }

  .insurance-logo {
    position: relative;
    left: 0;
    transform: translate(0, 0);
  }
`;

export const SmallText = styled.span`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 14px;
  }
`;
