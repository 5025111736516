import { userRoles } from '../constants/userRoles';

const USER_INFO = 'userInfo';

export const getUserInfo = () => JSON.parse(localStorage.getItem(USER_INFO));

// export const updateUserInfo = (props) => localStorage.setItem(USER_INFO, JSON.stringify(props));

// export const deleteUserFromStorage = () => {
//   localStorage.removeItem(USER_INFO);
// };

export const checkHost = () => {
  const { host, member, admin } = userRoles;
  const possibleRoles = [host, member, admin];
  const user = getUserInfo();
  return user ? possibleRoles.includes(user.role) : false;
};

export const checkGuest = () => {
  const { guest, member, admin } = userRoles;
  const possibleRoles = [guest, member, admin];
  const user = getUserInfo();
  return user ? possibleRoles.includes(user.role) : false;
};

export const checkObserver = () => {
  const { observer, member, admin } = userRoles;
  const possibleRoles = [observer, member, admin];
  const user = getUserInfo();
  return user ? possibleRoles.includes(user.role) : false;
};
