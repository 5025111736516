import styled from 'styled-components';
import IngosImage from './assets/images/ingosstrah.jpeg';
import { breakpoints } from './constants/breakpoints';

export const Company = styled.span`
  @media ${breakpoints.mobile} {
    color: ${(props) => props.color || '#066bd6'};
    font-weight: 800;
  }
`;

export const HostHighlighting = styled.span`
  @media ${breakpoints.mobile} {
    color: #06bb9b;
  }
`;

export const Title = styled.h2`
  @media ${breakpoints.mobile} {
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
    color: ${(props) => props.color || '#222222'};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px` || '15px'};
  }

  @media ${breakpoints.tablet} {
    font-size: 32px;
  }
`;

export const BackgroundWrapper = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    position: ${(props) => props.position || 'initial'};
    justify-content: center;
    width: 100%;
    background: ${(props) => props.background || '#ffffff'};
    background-size: cover;
    margin-top: ${({ marginTop }) => (marginTop ? '60px' : 0)};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? '60px' : 0)};
  }

  @media ${breakpoints.tablet} {
    background-size: cover;
  }

  @media ${breakpoints.laptop} {
    margin-top: ${({ marginTop }) => (marginTop ? '120px' : 0)};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? '100px' : 0)};
  }
`;

export const InsuranceVsk = styled.div`
  @media ${breakpoints.mobile} {
    position: absolute;
    bottom: 0;
    left: 50%;
    min-width: 170px;
    background-color: #fff;
    border-radius: 35px;
    transform: translate(-50%, 50%);
    padding: 8px 16px;

    .icon {
      height: 30px;
      width: 170px;
    }
  }

  @media ${breakpoints.laptop} {
    .icon {
      height: 50px;
      width: 240px;
    }
  }
`;

export const InsuranceIngostrah = styled.div`
  @media ${breakpoints.mobile} {
    position: absolute;
    background: url(${IngosImage}) no-repeat;
    background-position: center;
    background-size: 90%;
    bottom: 0;
    left: 50%;
    height: 40px;
    min-width: 170px;
    background-color: #fff;
    border-radius: 35px;
    transform: translate(-50%, 50%);
  }

  @media ${breakpoints.laptop} {
    height: 55px;
    width: 220px;
  }
`;

export const InsuranceRenins = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: absolute;
      bottom: 0;
      left: 50%;
      min-width: 170px;
      background-color: #fff;
      border-radius: 35px;
      transform: translate(-50%, 50%);
      padding: 15px 25px;
    }

    .icon {
      height: 35px;
      width: 130px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 10px 20px;
    }

    .icon {
      height: 55px;
      width: 240px;
    }
  }
`;

export const MainBlock = styled.div`
  @media ${breakpoints.mobile} {
    display: flex;
    width: 100%;
    max-width: 1440px;
    height: 660px;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    height: 710px;
  }

  @media ${breakpoints.laptop} {
    height: 830px;
  }
`;

export const Currency = styled.span`
  font-family: 'PT Sans', sans-serif;
`;

export const Card = styled.div`
  @media ${breakpoints.mobile} {
    background: #ffffff;
    border: ${(props) => (props.color ? `1px solid ${props.color}` : '1px solid #066bd6')};
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    max-width: 350px;
    margin-bottom: 20px;
    text-align: center;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  @media ${breakpoints.laptop} {
    width: 205px;
    margin: 0 15px 0 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media ${breakpoints.desktop} {
    width: 250px;
    margin: 0 20px 0 0;
  }
`;

export const CardHeader = styled.div`
  @media ${breakpoints.mobile} {
    background: ${(props) => props.color || '#066bd6'};
    padding: 24px;
    margin-bottom: 32px;
  }

  @media ${breakpoints.laptop} {
    margin-bottom: 40px;
  }
`;

export const CardContent = styled.div`
  @media ${breakpoints.laptop} {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .icon {
      width: 32px;
      height: 24px;
      margin: 0 0 44px;
    }
  }
`;

export const StyledError = styled.div`
  margin-top: 5px;
  color: red;
  width: 100%;
`;

export const Colored = styled.strong`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWidth || 'inherit'};
`;

export const Link = styled.span`
  @media ${breakpoints.mobile} {
    color: #066bd6;
    font-weight: 700;
    cursor: pointer;
    margin-top: 4px;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
`;

export const DialogTitle = styled.h3`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const DialogMiddleTitle = styled.h3`
  @media ${breakpoints.mobile} {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 32px;
  }
`;

export const DialogSubitle = styled.h4`
  @media ${breakpoints.mobile} {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const DialogText = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #222222;
  }

  @media ${breakpoints.tablet} {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const DialogImage = styled.div`
  @media ${breakpoints.mobile} {
    background: ${(props) => `url(${props.image})`};
    background-size: contain;
    width: ${(props) => props.mobileWidth};
    height: ${(props) => props.mobileHeight};
  }

  @media ${breakpoints.laptop} {
    width: ${(props) => props.laptopWidth || props.mobileWidth};
    height: ${(props) => props.laptopHeight || props.mobileHeight};
  }
`;

export const DialogWrapper = styled.div`
  @media ${breakpoints.mobile} {
    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 12px;
      height: 12px;
      cursor: pointer;

      & path {
        fill: #222b45;
      }
    }

    ${DialogTitle} {
      margin-bottom: 16px;
    }

    ${DialogMiddleTitle} {
      margin-bottom: 24px;
    }

    ${DialogSubitle} {
      margin-bottom: 16px;
    }

    ${DialogImage},
    .img {
      margin: 40px auto 24px;
    }

    ${DialogText} {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media ${breakpoints.tablet} {
    width: auto;
  }

  @media ${breakpoints.laptop} {
    ${DialogTitle} {
      margin-bottom: 24px;
    }

    ${DialogText} {
      margin-bottom: 24px;
    }
  }
`;

export const DialogBox = styled.div`
  @media ${breakpoints.mobile} {
    margin-bottom: ${(props) => (props.withMargin ? '60px' : '0')};
  }
`;

export const DialogList = styled.ul`
  @media ${breakpoints.mobile} {
    & {
      margin: 8px 0 16px;
    }

    li {
      display: flex;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      margin: 8px 0 24px;
    }
  }
`;

export const StyledLink = styled.a`
  @media ${breakpoints.mobile} {
    display: ${(props) => (props.block ? 'block' : 'initial')};
    color: #066bd6;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
`;
