import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const Title = styled.span`
  @media ${breakpoints.mobile} {
    & {
      color: var(--primary-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  @media ${breakpoints.mobile} {
    && {
      height: 1px;
      background-color: #edf1f7;
      margin: 20px 0;
    }
  }

  @media ${breakpoints.laptop} {
    && {
      margin: 25px 0;

      &:last-child {
        display: none;
      }
    }
  }
`;

export const InlineReservation = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: fixed;
      display: flex;
      justify-content: space-between;
      align-items: center;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;
      padding: 10px 16px;
      box-sizing: border-box;
      z-index: 999;
      border-top: 1px solid #e7e7e8;
      box-shadow: 0 4px 16px 0 rgba(72, 107, 125, 0.15);
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .number {
      color: var(--primary-text);
      font-weight: 700;
      font-size: 18px;
      margin-right: 5px;
    }

    .duration {
      color: var(--primary-text);

      font-weight: 400;
      font-size: 16px;
    }

    .price {
      /* margin-bottom: 5px; */
    }

    .discount {
      position: absolute;
      top: 10px;
      left: 80px;
      color: #ffffff;
      border-radius: 30px;
      background-color: #ffa600;
      margin-left: 15px;
      margin-right: 20px;
      padding: 6px 12px;
      display: inline-block;
      vertical-align: bottom;
    }

    .initialPrice {
      display: none;
    }

    .mobileSale > .number,
    .mobileSale > .duration {
      color: var(--grey-text);
      font-size: 12px;
    }

    .sale {
      display: none;
    }

    .currency {
      color: var(--primary-text);
      font-size: 20px;
    }

    .btn {
      font-size: 16px;
      height: 45px;
    }
  }

  @media ${breakpoints.tablet} {
    .currency {
      font-size: 24px;
      line-height: 37px;
    }

    .mobileSale > .number,
    .mobileSale > .duration {
      font-size: 16px;
    }

    .btn {
      font-size: 20px;
      height: 52px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: static;
      padding: 0;
      box-shadow: none;
      border-top: none;
    }

    .number {
      font-size: 30px;
      line-height: 40px;
    }

    .initialPrice {
      display: block;
      color: #95a3b8;
      text-decoration-line: line-through;
      margin-bottom: 10px;
      font-size: 16px;
    }

    .discount {
      position: static;
      display: inline-block;
    }

    .sale {
      display: block;
    }

    .sale > .number,
    .sale > .duration {
      color: var(--grey-text);
      font-size: 16px;
    }

    .mobileSale {
      display: none;
    }

    .currency {
      font-size: 32px;
      line-height: 40px;
    }

    .btn {
      display: none;
    }
  }
`;

export const Reservation = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 72px;
      background-color: #fff;
      z-index: 9;
    }

    .price {
      position: absolute;
      left: 10px;
      top: 17px;
    }

    .number {
      color: var(--primary-text);
      font-weight: 700;
      font-size: 20px;
    }

    .currency {
      color: var(--primary-text);
      font-size: 20px;
    }

    .duration {
      color: var(--primary-text);
      font-weight: 400;
      font-size: 16px;
    }

    .wrapper {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }

    .notice {
      color: var(--primary-text);
      font-size: 12px;
    }

    .btn {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }

  @media ${breakpoints.tablet} {
    .number {
      font-size: 24px;
    }

    .price {
      left: 25px;
      top: 11px;
    }

    .wrapper {
      bottom: 10px;
      left: 25px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 16px;
    }

    .price {
      display: none;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 11;
    }

    .btn {
      position: initial;
      width: 100%;
      margin: 24px 0 8px 0;
    }

    .wrapper {
      position: initial;
      text-align: center;
    }
  }
`;

export const SelectDate = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      height: 24px;
      align-items: center;
      justify-content: space-between;
      border: ${(props) => (props.error ? '1px solid red' : '1px solid #c4c4c4')};
      border-radius: 4px;
      background-color: #fff;
      margin-top: 6px;
      padding: 12px 12px 12px 16px;
    }

    .picker-input {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${(props) => (props.error ? 'red' : 'inherit')};
    }

    .picker-wrap {
      position: relative;
      width: 200px;
    }

    .picker-wrap .DayPicker-Months {
      flex-wrap: nowrap;
    }

    .picker-wrap
      .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: rgb(220, 230, 242) !important;
      color: var(--primary-text);
      border-radius: 0;
    }

    .picker-wrap > div {
      position: absolute;
      left: 130px;
      top: 35px;
      transform: translate(-40%, 0);
      border: 1px solid #d5d5d5;
      border-radius: 10px;
      background: #fff;
      z-index: 10;
    }

    .hours:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 36px;
      z-index: 10;
      background: #c4c4c4;
      top: 50%;
      left: 0;
      transform: translate(1px, -50%);
      opacity: 0.5;
    }

    .hours
      .MuiAutocomplete-inputRoot[class*='MuiInput-root']
      .MuiAutocomplete-input.MuiAutocomplete-input:first-child {
      border: none;
      width: 75px;
      color: ${(props) => (props.error ? 'red' : 'inherit')};
      font-size: 14px;
    }

    .MuiAutocomplete-endAdornment {
      right: -25px;
    }
  }

  @media ${breakpoints.tablet} {
    .picker-input {
      font-size: 16px;
    }

    .hours
      .MuiAutocomplete-inputRoot[class*='MuiInput-root']
      .MuiAutocomplete-input.MuiAutocomplete-input:first-child {
      font-size: 16px;
    }
  }

  @media ${breakpoints.laptop} {
    .hours div {
      z-index: 99;
    }
  }
`;

export const Rent = styled.div`
  @media ${breakpoints.mobile} {
    > .-end {
      margin-top: 18px;
      margin-bottom: 15px;
    }

    .select-hours {
      display: flex;
      height: 100%;
      width: 110px;
    }

    .select-hours .MuiSelect-select {
      border: none;

      &:focus {
        background: none;
      }
    }

    .duration {
      color: var(--primary-text);
      font-weight: 400;
      font-size: 16px;
    }

    .day {
      font-weight: 400;
      font-size: 14px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      display: flex;
      justify-content: space-between;
    }

    > .-end {
      margin-top: 0;
    }

    .picker {
      width: calc(50% - 10px);
    }

    .picker-wrap {
      max-width: 210px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      flex-direction: column;
      margin-bottom: 0;
    }

    > .-end {
      margin-top: 24px;
    }

    .picker {
      width: 100%;
    }

    .picker-wrap {
      max-width: 130px;
    }
  }
`;

export const Price = styled.span`
  display: inline-block;
  opacity: 0.7;
  border-radius: 4px;
  background-color: #def2ff;
  padding: 5px 8px;
  color: var(--dark-text);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 8px;
`;

export const Message = styled.span`
  margin: 8px 0;
`;

export const CustomDelivery = styled.span`
  margin: 16px 0;
`;

export const SliderSection = styled.div`
  & {
    margin-top: 20px;
  }

  .footnote {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #5d5d5d;
    margin-top: -10px;
    display: block;
  }

  .duration {
    color: var(--primary-text);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .price {
    color: var(--primary-text);
    font-weight: 600;
    font-size: 16px;
  }

  @media ${breakpoints.mobile} {
    ${Title} {
      display: block;
      margin: 20px 0 16px;
    }
  }

  @media ${breakpoints.tablet} {
    ${Title} {
      margin: 30px 0 24px;
    }
  }
`;

export const PackagePriceWrapper = styled.div`
  & {
    margin: 13px 0 0;
    display: flex;
    align-items: baseline;
  }

  .duration {
    margin: 0;
  }

  @media ${breakpoints.mobile} {
    ${Title} {
      margin: 0 0 0 5px;
    }
  }
`;
