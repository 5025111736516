export const YANDEX_CONSTANTS = Object.freeze({
  TRACKER: 'yandex',
  ACTIONS: {
    REACH_GOAL: 'reachGoal',
  },
  GOALS: {
    CAR_REGISTRATION_HEADER: 'buttonhost1',
    CAR_REGISTRATION_HOST_1: 'buttonhost2',
    CAR_REGISTRATION_HOST_2: 'buttonhost3',
    CAR_REGISTRATION_GUEST_1: 'buttonhost4',
    CAR_REGISTRATION_GUEST_2: 'buttonhost5',
    CAR_REGISTRATION_GUEST_3: 'buttonhost6',
    HOMEPAGE_SEARCH: 'homepage_search',
    HOMEPAGE_APPSTORE: 'homepage_appstore',
    HOMEPAGE_GOOGLEPLAY: 'homepage_googleplay',
    HEADER_SIGNUP: 'header_signup',
    HEADER_LEND_YOUR_CAR: 'header_lend_your_car',
    HEADER_SHOW_MORE: 'header_show_more',
    ABOUT_RENT_CAR: 'about_rent_car',
    ABOUT_REGISTER_CAR: 'about_register_car',
    CALCULATOR_ESTIMATE: 'calculator_estimate',
    RENTAL_INCOME_CALCULATOR: 'rental_income_calculator',
    RENTAL_INCOME_CAR_REGISTRATION_TOP: 'rental_income_car_registration_top',
    RENTAL_INCOME_CAR_REGISTRATION_BOTTOM: 'rental_income_car_registration_bottom',
    FOOTER_BLOG: 'footer_blog',
    FOOTER_APPSTORE: 'footer_appstore',
    FOOTER_GOOGLEPLAY: 'footer_googleplay',
    FOOTER_FACEBOOK: 'footer_facebook',
    FOOTER_INSTAGRAM: 'footer_instagram',
    FOOTER_VK: 'footer_vk',
    FOOTER_CALCULATOR: 'footer_calculator',
    KRASNODAR_FIND_CAR: 'krasnodar_find_car',
    KRASNODAR_BOOK_CAR: 'krasnodar_book_car',
    KRASNODAR_ALL_OFFERS: 'krasnodar_all_offers',
    KRASNODAR_CATEGORY_BUDGET: 'krasnodar_category_budget',
    KRASNODAR_CATEGORY_MINIVAN: 'krasnodar_category_minivan',
    KRASNODAR_CATEGORY_PREMIUM: 'krasnodar_category_premium',
    KRASNODAR_CATEGORY_CROSSOVER: 'krasnodar_category_crossover',
    MOSCOW_FIND_CAR: 'moscow_find_car',
    MOSCOW_BOOK_CAR: 'moscow_book_car',
    MOSCOW_ALL_OFFERS: 'moscow_all_offers',
    MOSCOW_CATEGORY_BUDGET: 'moscow_category_budget',
    MOSCOW_CATEGORY_MINIVAN: 'moscow_category_minivan',
    MOSCOW_CATEGORY_PREMIUM: 'moscow_category_premium',
    MOSCOW_CATEGORY_CROSSOVER: 'moscow_category_crossover',
  },
});

export const GOOGLE_CONSTANTS = Object.freeze({
  TRACKER: 'google',
  ACTIONS: {
    EVENT: 'event',
  },
  CATEGORIES: {
    HOST: 'host',
    HEADER: 'header',
    HOMEPAGE: 'homepage',
    ABOUT: 'about',
    CALCULATOR: 'calculator',
    RENTAL_INCOME: 'rental_income',
    FOOTER: 'footer',
    KRASNODAR: 'krasnodar',
    KRASNODAR_CATEGORY: 'krasnodar_category',
    MOSCOW: 'moscow',
    MOSCOW_CATEGORY: 'moscow_category',
  },
  GOALS: {
    CAR_REGISTRATION_HEADER: 'button1',
    CAR_REGISTRATION_HOST_1: 'button2',
    CAR_REGISTRATION_HOST_2: 'button3',
    CAR_REGISTRATION_GUEST_1: 'button4',
    CAR_REGISTRATION_GUEST_2: 'button5',
    CAR_REGISTRATION_GUEST_3: 'button9',
    HOMEPAGE_SEARCH: 'search',
    HOMEPAGE_APPSTORE: 'appstore',
    HOMEPAGE_GOOGLEPLAY: 'googleplay',
    HEADER_SIGNUP: 'signup',
    HEADER_LEND_YOUR_CAR: 'lend_your_car',
    HEADER_HOW_IT_WORK: 'how_it_work',
    ABOUT_RENT_CAR: 'rent_car',
    ABOUT_REGISTER_CAR: 'register_car',
    CALCULATOR_ESTIMATE: 'estimate',
    RENTAL_INCOME_CALCULATOR: 'calculator',
    RENTAL_INCOME_CAR_REGISTRATION_TOP: 'car_registration_top',
    RENTAL_INCOME_CAR_REGISTRATION_BOTTOM: 'car_registration_bottom',
    FOOTER_BLOG: 'blog',
    FOOTER_APPSTORE: 'appstore',
    FOOTER_GOOGLEPLAY: 'googleplay',
    FOOTER_FACEBOOK: 'facebook',
    FOOTER_INSTAGRAM: 'instagram',
    FOOTER_VK: 'vk',
    FOOTER_CALCULATOR: 'calculator',
    CITY_FIND_CAR: 'find_car',
    CITY_BOOK_CAR: 'book_car',
    CITY_ALL_OFFERS: 'all_offers',
    CITY_CATEGORY_BUDGET: 'budget',
    CITY_CATEGORY_MINIVAN: 'minivan',
    CITY_CATEGORY_PREMIUM: 'premium',
    CITY_CATEGORY_CROSSOVER: 'crossover',
  },
});
