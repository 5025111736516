import React from 'react';
import Button from '@material-ui/core/Button';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import CloseIcon from '../../assets/icons/close.svg';
import ImageIcon from '../../assets/icons/two-people.svg';

import {
  DialogWrapper,
  DialogMiddleTitle,
  DialogSubitle,
  DialogText,
} from '../../styledComponents';
import {
  PopupContainer,
  StyledTimeline,
  StyledTimelineDot,
  StyledTimelineItem,
  StyledTimelineContent,
} from './styles';

const titleString = 'Расскажите о Getarent своим друзьям';

const content = [
  {
    step: 1,
    title: 'Получите 3 000 ₽ за каждую новую машину',
    text:
      'Если по вашей рекомендации зарегистрируется новая машина, вы получите вознаграждение в 3 000 рублей: 1 500 ₽ после регистрации и 1 500 ₽ после первой аренды',
  },
  {
    step: 2,
    title: 'Получите 1 000 ₽ за каждого нового путешественника',
    text: 'Если по вашей рекомендации новый путешественник забронирует машину',
  },
];

const textString =
  'Владельцы машин получат вознаграждение в виде выплаты, а путешественники - в виде скидок на поездки. Новому пользователю при регистрации достаточно сообщить ваш телефон';

const buttonText = 'Спасибо, все понятно';

const ReferalPopup = ({ onClose }) => {
  return (
    <DialogWrapper>
      <CloseIcon className="close-icon" onClick={onClose} />
      <PopupContainer>
        <ImageIcon className="img-icon" />
        <DialogMiddleTitle>{titleString}</DialogMiddleTitle>
        <StyledTimeline>
          {content.map(({ step, title, text }) => (
            <StyledTimelineItem key={step}>
              <TimelineSeparator>
                <StyledTimelineDot>{step}</StyledTimelineDot>
              </TimelineSeparator>
              <StyledTimelineContent>
                <DialogSubitle>{title}</DialogSubitle>
                <DialogText>{text}</DialogText>
              </StyledTimelineContent>
            </StyledTimelineItem>
          ))}
        </StyledTimeline>
        <DialogText>{textString}</DialogText>
        <Button onClick={onClose} variant="contained" color="secondary" className="btn">
          {buttonText}
        </Button>
      </PopupContainer>
    </DialogWrapper>
  );
};

export default ReferalPopup;
