import {
  addDays,
  addMonths,
  addHours,
  getMinutes,
  setMinutes,
  format as formatDateFns,
  formatISO,
  parse,
  parseISO,
  differenceInMinutes,
} from 'date-fns';

export const defaultRentPeriod = 14;

export const hoursForPrepearing = 5;

export const getDate = (dateISO, format = 'dd.MM.yyyy', locale = 'en') => {
  const date = parseISO(dateISO);
  return formatDateFns(date, format, locale);
};

export const getTime = (dateISO) => {
  const date = parseISO(dateISO);
  return formatDateFns(date, 'HH:mm');
};

export const getRentDuration = (end, start) => {
  const duration = Math.ceil(differenceInMinutes(end, start) / 1440) || 0;
  return duration;
};

export const joinDayAndHours = (day, hours) => `${day}T${hours}`;

export const getRentInitialValues = ({ dateStart, dateEnd }) => {
  const dayStart = parseISO(dateStart);
  const dayEnd = parseISO(dateEnd);
  const dayISOStart = getDate(dateStart, 'yyyy-MM-dd');
  const timeStart = getTime(dateStart);
  const dayISOEnd = getDate(dateEnd, 'yyyy-MM-dd');
  const timeEnd = getTime(dateEnd);
  const initialValues = { dayStart, dayEnd, dayISOStart, timeStart, dayISOEnd, timeEnd };
  return initialValues;
};

export const formatDateISO = (day) => formatISO(day, { representation: 'date' });

export const defaultStartRent = () => {
  const startMinutes = getMinutes(new Date()) < 30 ? 30 : 0;
  const startHours = addHours(setMinutes(new Date(), startMinutes), hoursForPrepearing);
  const startDate = addMonths(startHours, 1);
  return formatDateFns(startDate, "yyyy-MM-dd'T'HH:mm");
};

export const defaultEndRent = (start) => {
  const startDate = parse(start, "yyyy-MM-dd'T'HH:mm", new Date());
  const endDate = addDays(startDate, defaultRentPeriod);
  return formatDateFns(endDate, "yyyy-MM-dd'T'HH:mm");
};

export const endRent = (start, duration) => {
  const startDate = parse(start, "yyyy-MM-dd'T'HH:mm", new Date());
  const endDate = addDays(startDate, duration);
  return formatDateFns(endDate, "yyyy-MM-dd'T'HH:mm");
};

export const startRentWithDelay = (days) => {
  const startMinutes = getMinutes(new Date()) < 30 ? 30 : 0;
  const startDate = addDays(
    addHours(setMinutes(new Date(), startMinutes), hoursForPrepearing),
    days,
  );
  return formatDateFns(startDate, "yyyy-MM-dd'T'HH:mm");
};

export const getDateQuery = (delay = 0, duration = 5) => {
  const dateStart = startRentWithDelay(delay);
  const dateEnd = endRent(dateStart, duration);
  const dateQuery = `&dateStart=${encodeURIComponent(dateStart)}&dateEnd=${encodeURIComponent(
    dateEnd,
  )}`;
  return dateQuery;
};
