import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { googleTracks, track, yandexTracks } from '../../seo';
import { footerContent } from '../../assets/data/footer-content';
import AppLinks from '../ui-kit/appLinks';
import ArrowDownIcon from '../../assets/icons/arrow-down.svg';
import MastercardIcon from '../../assets/icons/mastercard.svg';
import mir from '../../assets/images/mir-logo.png';
import visa from '../../assets/images/visa.png';
// import VkIcon from '../../assets/icons/vk.svg';
// import FacebookIcon from '../../assets/icons/fb.svg';
// import InstagramIcon from '../../assets/icons/instagram.svg';
import {
  Box,
  CardsWrapper,
  Contact,
  Footer,
  FooterContainer,
  ImgContainer,
  Item,
  LastBox,
  List,
  Socials,
  TextBlock,
  TextLink,
  Title,
} from './styles';

const secondRowContent = [
  {
    title: 'Города',
    id: 'cities',
    list: [
      {
        key: 'krasnodar',
        link: '/car-rental-krasnodar',
        name: 'Краснодар',
        target: '_self',
      },
    ],
  },
];

const FooterOld = ({ searchFilter }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Footer>
      {isMobile ? (
        <>
          {footerContent.map(({ title, id, list }) => (
            <Accordion key={id}>
              <AccordionSummary aria-controls={`aria-${id}`} id={id} expandIcon={<ArrowDownIcon />}>
                <Title>{title}</Title>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {list.map(({ name, key, link, target, seo }) => (
                    <Item key={key}>
                      <TextLink
                        title={name}
                        href={key === 'search' ? `${link}?${searchFilter}` : link}
                        rel="noreferrer"
                        target={target}
                        onClick={() => {
                          if (seo) {
                            track(seo);
                          }
                        }}
                      >
                        {name}
                      </TextLink>
                    </Item>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
          <Socials>
            <Contact>
              <a className="contacts" title="+88003507780" href="tel:88003507780">
                8 (800) 350-77-80
              </a>
            </Contact>
            <Contact>
              <a title="support@getarent.ru" href="mailto:support@getarent.ru">
                support@getarent.ru
              </a>
            </Contact>
            <Item>Безопасные платежи</Item>
            <CardsWrapper>
              <ImgContainer>
                <img src={mir} />
              </ImgContainer>
              <ImgContainer>
                <img src={visa} />
              </ImgContainer>
              <ImgContainer>
                <MastercardIcon />
              </ImgContainer>
            </CardsWrapper>
            <AppLinks
              hugeMargin
              seo={{
                apple: [yandexTracks.footerAppstore, googleTracks.footerAppstore],
                google: [yandexTracks.footerGoogleplay, googleTracks.footerGoogleplay],
              }}
            />
          </Socials>
          <LastBox>
            <TextBlock>
              Страховая защита предоставляется СПАО «Ингосстрах» на основании генеральных договоров.
              2022, ООО “ГЕПТОП” ИНН 2311296837. Регистрационный номер в реестре операторов
              персональных данных 23-21-011189
            </TextBlock>
            <Item fontSize="10px">
              <TextLink
                title="Политика конфиденциальности"
                href="https://storage.yandexcloud.net/getarent-documents-bucket/c6364667735bbdaf.pdf"
                rel="noreferrer"
                target="_target"
              >
                Политика конфиденциальности
              </TextLink>
            </Item>
          </LastBox>
        </>
      ) : (
        <>
          <FooterContainer>
            {footerContent.map(({ title, id, list }) => (
              <Box key={id} id={id}>
                <Title>{title}</Title>
                <List>
                  {list.map(({ name, key, link, target, seo }) => (
                    <Item key={key}>
                      <TextLink
                        title={name}
                        href={key === 'search' ? `${link}${searchFilter}` : link}
                        rel="noreferrer"
                        target={target}
                        onClick={() => {
                          if (seo) {
                            track(seo);
                          }
                        }}
                      >
                        {name}
                      </TextLink>
                    </Item>
                  ))}
                </List>
              </Box>
            ))}
            <Socials>
              <Contact>
                <a className="contacts" title="+88003507780" href="tel:+88003507780">
                  8 (800) 350-77-80
                </a>
              </Contact>
              <AppLinks
                hugeMargin
                seo={{
                  apple: [yandexTracks.footerAppstore, googleTracks.footerAppstore],
                  google: [yandexTracks.footerGoogleplay, googleTracks.footerGoogleplay],
                }}
              />
              <Contact>
                <a title="support@getarent.ru" href="mailto:support@getarent.ru">
                  support@getarent.ru
                </a>
              </Contact>
              <Item>Безопасные платежи</Item>
              <CardsWrapper>
                <ImgContainer>
                  <img src={mir} />
                </ImgContainer>
                <ImgContainer>
                  <img src={visa} />
                </ImgContainer>
                <ImgContainer>
                  <MastercardIcon />
                </ImgContainer>
              </CardsWrapper>
            </Socials>
          </FooterContainer>
          <LastBox>
            <TextBlock>
              Страховая защита предоставляется СПАО «Ингосстрах» на основании генеральных договоров.
              2022, ООО “ГЕПТОП” ИНН 2311296837. Регистрационный номер в реестре операторов
              персональных данных 23-21-011189
            </TextBlock>
            <Item fontSize="10px">
              <TextLink
                title="Политика конфиденциальности"
                href="https://storage.yandexcloud.net/getarent-documents-bucket/c6364667735bbdaf.pdf"
                rel="noreferrer"
                target="_target"
              >
                Политика конфиденциальности
              </TextLink>
            </Item>
          </LastBox>
        </>
      )}
    </Footer>
  );
};

export default FooterOld;
