import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakpoints';

export const AppLinksWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
    }

    .app-link {
      display: inline-block;
      height: 40px;
    }

    .app-link:first-child {
      margin-right: ${(props) => (props.hugeMargin ? '16px' : '6px')};
    }
  }

  @media ${breakpoints.laptop} {
    .app-link {
    }
  }
`;
