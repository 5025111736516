import styled from 'styled-components';

export const AppLinksWrapper = styled.div`
  .app-link {
    display: inline-block;
  }

  .app-link:first-child {
    margin-right: 16px;
  }
`;
