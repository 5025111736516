import axios from 'axios';
import { SIGNOUT_URL, REFRESH_TOKENS_URL } from '../../constants/endpoints';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const USER_INFO = 'userInfo';

export const deleteUserFromStorage = () => {
  localStorage.removeItem(USER_INFO);
};

export const apiFetch = (method, url, data, headers = {}) =>
  axios.request({
    url,
    data,
    method,
    headers: { ...defaultHeaders, ...headers },
    withCredentials: true,
    crossDomain: true,
  });

export const makeRefreshCall = async () => {
  try {
    const { data } = await apiFetch('POST', REFRESH_TOKENS_URL);
    return data;
  } catch (error) {
    // TODO: add error handling
    deleteUserFromStorage();
    window.location = '/';
    throw error;
  }
};

export const makeCall = async (...options) => {
  let response;
  try {
    response = await apiFetch(...options);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await makeRefreshCall();
      response = await apiFetch(...options);
      return response;
    }
    throw error;
  }
};

export const signOutHandler = async () => {
  try {
    await makeCall('POST', SIGNOUT_URL);
  } catch (error) {
    deleteUserFromStorage();
    return;
  }
  deleteUserFromStorage();
};
