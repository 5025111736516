import React from 'react';

import { track } from '../../../seo';
import AppStoreIcon from '../../../assets/icons/app_store.svg';
import GooglePlayIcon from '../../../assets/icons/google-play.svg';

import { AppLinksWrapper } from './styles';

const AppLinks = ({ className, hugeMargin = false, seo: { google, apple } = {} }) => (
  <AppLinksWrapper className={className} hugeMargin={hugeMargin}>
    <a
      rel="noreferrer"
      className="app-link"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=ru.getarent.app"
      onClick={() => {
        if (google) {
          track(google);
        }
      }}
    >
      <GooglePlayIcon />
    </a>
    <a
      rel="noreferrer"
      className="app-link"
      target="_blank"
      href="https://apps.apple.com/us/app/getarent-%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D1%80%D1%88%D0%B5%D1%80%D0%B8%D0%BD%D0%B3/id1541999129"
      onClick={() => {
        if (apple) {
          track(apple);
        }
      }}
    >
      <AppStoreIcon />
    </a>
  </AppLinksWrapper>
);

export default AppLinks;
