import { yandexTracks, googleTracks } from '../../seo';
import { getDateQuery } from '../../utils/date';

const dateQuery = getDateQuery(30, 14);

export const userMobileNavigationSchema = [
  {
    title: 'Как арендовать?',
    key: 'how-to-rent',
    path: 'https://help.getarent.ru/knowledge-bases/2/articles/192-o-servise',
  },
  {
    title: 'Мои поездки',
    key: 'my-rents-guest',
    path: '/app/registration',
  },
  {
    title: 'Мои поездки',
    key: 'my-rents-host-gost',
    path: '/app/my-rents',
  },
  {
    title: 'Автопарк',
    key: 'my-cars',
    path: '/app/my-cars',
  },
  {
    title: 'Аккаунт',
    key: 'account',
    path: '/app/account',
  },
  {
    title: 'Сдать авто в аренду',
    key: 'car-rental-income',
    path: '/car-rental-income',
    // seo: [yandexTracks.headerLendYourCar, googleTracks.headerLendYourCar],
  },
];

export const desktopNavigationSchema = [
  {
    title: 'Вопросы и ответы',
    key: 'help',
    path: 'https://help.getarent.ru',
  },
  {
    title: 'Служба поддержки',
    key: 'help-service',
    path: 'https://help.getarent.ru/ru/knowledge-bases/2/articles/128-kontaktyi',
  },
  {
    title: 'Как арендовать?',
    key: 'help-rent',
    path: 'https://help.getarent.ru/knowledge-bases/2/articles/192-o-servise',
  },
  {
    title: 'Аренда для юр. лиц',
    key: 'help-lawyer',
    path:
      'https://help.getarent.ru/knowledge-bases/2/articles/193-arenda-avto-dlya-yuridicheskih-lits',
  },
];
