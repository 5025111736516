/* eslint no-underscore-dangle: 0 */
if (typeof window !== 'undefined') {
  const SERVICE_BASE_URL = window._env_.GATSBY_SERVICE_BASE_URL;

  if (!SERVICE_BASE_URL) {
    throw new Error(`Improperly configured: Env var GATSBY_SERVICE_BASE_URL is not defined`);
  }

  module.exports = {
    AUTH_SERVICE_URL: `${SERVICE_BASE_URL}/auth`,
    SIGNOUT_URL: `${SERVICE_BASE_URL}/auth/logout`,
    REFRESH_TOKENS_URL: `${SERVICE_BASE_URL}/auth/refresh`,
    GET_SUGGESTS_URL: `${SERVICE_BASE_URL}/car-search/suggest`,
    GET_SERVICE_STATS_URL: `${SERVICE_BASE_URL}/manuals/service-stats`,
    GET_TOP_HOSTS_URL: `${SERVICE_BASE_URL}/manuals/top-hosts`,
    GET_CITIES_URL: `${SERVICE_BASE_URL}/manuals/cities`,
    SEARCH_CAR_URL: `${SERVICE_BASE_URL}/car-search`,
    BEST_REVIEWS_URL: `${SERVICE_BASE_URL}/rating/v1/best-reviews`,
    CARCULATOR_FORM_URL: `${SERVICE_BASE_URL}/car/application`,
  };
}
