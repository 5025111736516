import React, { useEffect } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { isBrowser } from '../../utils/isBrowser';
import theme from '../../theme';
import Header from '../header';
import FooterOld from '../footerOld';

import { Container } from './styles';

const Layout = ({ searchFilter = '', children }) => {
  // useEffect(() => {
  //   if (isBrowser && window.yaCounter68153707) {
  //     window.yaCounter68153707.hit(window.location.href);
  //   }
  // }, [isBrowser]);

  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <Header />
        {children}
        <FooterOld searchFilter={searchFilter} />
      </Container>
    </MuiThemeProvider>
  );
};

export default Layout;
