import React from 'react';
import AppStoreIcon from './app_store.svg';
import GooglePlayIcon from './google-play.svg';

import { AppLinksWrapper } from './styles';

console.log(GooglePlayIcon);
export const AppLinks = () => (
  <AppLinksWrapper>
    <a
      className="app-link"
      target="_blank"
      href="https://apps.apple.com/us/app/getarent-%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D1%80%D1%88%D0%B5%D1%80%D0%B8%D0%BD%D0%B3/id1541999129"
    >
      <img src={AppStoreIcon} alt="" style={{ marginBottom: 0 }} />
    </a>
    <a
      className="app-link"
      target="_blank"
      href="https://play.google.com/store/apps/details?id=ru.getarent.app"
    >
      <img src={GooglePlayIcon} alt="" style={{ marginBottom: 0 }} />
    </a>
  </AppLinksWrapper>
);
