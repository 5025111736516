import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { AppLinks } from './appLinks';
import { Title } from './styles';

export const MobileAppAttemptDialog = ({ isModalOpen, onClose }) => (
  <Dialog
    maxWidth="xs"
    fullWidth={true}
    open={isModalOpen}
    aria-labelledby="max-width-dialog-title"
    aria-describedby="alert-dialog-description"
    onClose={onClose}
    disablePortal
  >
    <DialogTitle id="alert-dialog-title">
      <Title>На данный момент бронирование автомобиля доступно только в приложении Getarent</Title>
    </DialogTitle>
    <DialogContent>
      <CloseIcon
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          fill: '#5d5d5d',
          cursor: 'pointer',
        }}
        onClick={onClose}
      />
      <AppLinks />
    </DialogContent>
  </Dialog>
);
