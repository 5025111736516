import React, { useCallback, useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LinkButton from '@material-ui/core/Link';
import PersonIcon from '@material-ui/icons/Person';

import { desktopNavigationSchema } from '../navigationSchema';
import { checkHost, checkObserver, getUserInfo } from '../../../services/auth-service';
import { signOutHandler } from '../signOutHandler';
import { googleTracks, track, yandexTracks } from '../../../seo';
import QuestionMark from '../../../assets/icons/question-mark.svg';
import { AuthorizationWrap, DesktopNavWrapper, NavigationMenu, UserPanel } from './styles';
import { MobileAppAttemptDialog } from '../../mobileAppAttemptDialog';

export const DesktopNav = () => {
  const [anchorNavigation, setAnchorNavigation] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const [isObserver, setIsObserver] = useState(false);
  const [isMobileBlocking, setIsMobileBlocking] = useState(false);

  useEffect(() => {
    setIsLoggedIn(getUserInfo());
    setIsHost(checkHost());
    setIsObserver(checkObserver());
  }, []);

  const openNavigation = useCallback((event) => {
    track([yandexTracks.headerShowMore]);
    setAnchorNavigation(event.currentTarget);
  }, []);

  const closeNavigation = useCallback(() => {
    setAnchorNavigation(null);
  }, []);

  const signOutClick = async () => {
    try {
      await signOutHandler();
      setIsLoggedIn(false);
      setIsHost(false);
      setIsObserver(false);
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <div>
      <DesktopNavWrapper>
        <NavigationMenu>
          <a
            href="/car-rental-income"
            title="Сдать авто в аренду"
            className="link -register"
            underline="none"
            onClick={() => {
              track([yandexTracks.headerLendYourCar, googleTracks.headerLendYourCar]);
            }}
          >
            Сдать авто в аренду
          </a>
          {!isLoggedIn && (
            <span
              onClick={() => setIsMobileBlocking(true)}
              /* href="/app/registration" */ title="Мои поездки"
              className="link"
              underline="none"
            >
              Мои поездки
            </span>
          )}
          {isLoggedIn && !isObserver && (
            <span
              onClick={() => setIsMobileBlocking(true)}
              /* href="/app/my-rents" */ title="Мои поездки"
              className="link"
              underline="none"
            >
              Мои поездки
            </span>
          )}
          {isHost && (
            <span
              onClick={() => setIsMobileBlocking(true)}
              /* href="/app/my-cars" */ title="Мои машины"
              className="link"
              underline="none"
            >
              Автопарк
            </span>
          )}
        </NavigationMenu>
        <AuthorizationWrap>
          <span color="primary" underline="none" className="phone">
            8 (800) 350-77-80
          </span>
          <LinkButton
            aria-controls="navigation-menu"
            aria-haspopup="true"
            onClick={openNavigation}
            className="link"
            underline="none"
          >
            <QuestionMark />
          </LinkButton>
          <Menu
            id="navigation-menu"
            keepMounted
            disablePortal
            marginThreshold={0}
            anchorEl={anchorNavigation}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorNavigation)}
            classes={{ paper: 'menu-paper' }}
            onClose={closeNavigation}
          >
            {desktopNavigationSchema.map(({ title, key, path, help }) => (
              <a target="_blank" href={path} title={title} className="link -item" underline="none">
                <MenuItem key={key} onClick={closeNavigation} className="item">
                  {title}
                </MenuItem>
              </a>
            ))}
          </Menu>
          {isLoggedIn ? (
            <UserPanel>
              <a href="/app/account" className="icon-wrap" underline="none">
                <PersonIcon className="menu-icon" />
              </a>
              <a title="Выход" onClick={signOutClick}>
                <span color="primary" className="link" underline="none">
                  Выход
                </span>
              </a>
            </UserPanel>
          ) : (
            <span
              onClick={() => setIsMobileBlocking(true)}
              /* href="/app/registration" */
              title="Вход"
              // onClick={() => track([yandexTracks.headerSignup, googleTracks.headerSignup])}
            >
              <span color="primary" className="link -login" underline="none">
                Войти/Зарегистрироваться
              </span>
            </span>
          )}
        </AuthorizationWrap>

        <MobileAppAttemptDialog
          isModalOpen={isMobileBlocking}
          onClose={() => setIsMobileBlocking(false)}
        />
      </DesktopNavWrapper>
    </div>
  );
};
