import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakpoints';

export const UserPanel = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
      align-items: center;
    }

    .icon-wrap {
      &:hover {
        background: none;
      }
    }
  }
`;

export const NavigationMenu = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
      align-items: center;
    }

    .menu-paper {
      margin-top: 15px;
    }
  }
`;

export const AuthorizationWrap = styled.div`
  & {
    display: flex;
    align-items: center;
  }

  .link {
    font-family: 'Open Sans', sans-serif;
  }
`;

export const DesktopNavWrapper = styled.div`
  @media ${breakpoints.tablet} {
    & {
      display: flex;
    }

    .item {
      width: 100%;
      color: #303133;
      font-size: 14px;
    }

    .link {
      color: #303133;
      margin-left: 30px;
      font-size: 14px;
      cursor: pointer;

      &.-register {
        font-weight: 600;
        color: #066bd6;
      }

      &.-item {
        margin-left: 0;
        width: 100%;
      }
    }

    ${AuthorizationWrap} {
      & {
        padding-left: 32px;
        margin-left: 32px;
        border-left: 1px solid #c4c4c4;
      }

      .phone {
        color: #303133;
        font-weight: 700;
      }
    }

    ${UserPanel} {
      margin-left: 30px;
    }
  }
`;
