import styled from 'styled-components';
import { styled as materialStyled } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { breakpoints } from '../../constants/breakpoints';

export const Header = styled(AppBar)`
  @media ${breakpoints.mobile} {
    & {
      display: flex;
      align-items: center;
      background: #fff;
      width: 100%;
    }
  }

  @media ${breakpoints.tablet} {
    .contacts {
      display: none;
    }
  }

  @media ${breakpoints.laptop} {
    .contacts {
      display: block;
      margin: 0 0 0 auto;
      color: rgb(6, 107, 214);
      font-size: 14px;
      cursor: pointer;
      padding-right: 20px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Content = styled.div`
  @media ${breakpoints.mobile} {
    && {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;
      width: 100%;
      max-width: 1440px;
      box-sizing: border-box;
      background: #fff;
      padding: 0 3%;
    }

    .logo {
      width: 40px;
      height: 24px;
      margin: 0;
    }

    .toolbar {
      display: flex;
      justify-content: flex-end;
      width: 37px;
      min-height: 100%;
      padding: 0;
    }

    .icon-wrap {
      width: 35px;
      height: 35px;
      z-index: 1201;
      color: #000;
      padding: 0;
    }

    .avatar {
      width: 35px;
      height: 35px;
    }

    .menu-icon {
      width: 35px;
      height: 35px;
      color: #5d5d5d;
    }

    .link {
      display: flex;
    }
  }

  @media ${breakpoints.tablet} {
    .toolbar {
      width: auto;
      margin-left: auto;
    }

    .logo {
      width: 150px;
      height: 30px;
    }
  }
`;

export const StyledTimelineItem = materialStyled(TimelineItem)({
  [`@media ${breakpoints.mobile}`]: {
    minHeight: '0',

    '&:before': {
      padding: 0,
    },
  },
});

export const StyledTimelineContent = materialStyled(TimelineContent)({
  [`@media ${breakpoints.mobile}`]: {
    padding: '0 0 16px 16px',
    marginBottom: '40px',

    '&:last-child': {
      padding: '0 0 0 16px',
      marginBottom: '40px',
    },
  },
  [`@media ${breakpoints.laptop}`]: {
    padding: '0 0 16px 20px',

    '&:last-child': {
      padding: '0 0 0 20px',
    },
  },
});

export const StyledTimeline = materialStyled(Timeline)({
  [`@media ${breakpoints.mobile}`]: {
    marginBottom: '0',
    paddingLeft: '0',
    width: 'auto',
  },
  [`@media ${breakpoints.laptop}`]: {
    '&:last-child': {
      marginBottom: '0',
    },
  },
});

export const StyledTimelineDot = materialStyled(TimelineDot)({
  [`@media ${breakpoints.mobile}`]: {
    width: '30px',
    height: '30px',
    background: '#ffffff',
    border: '2px solid #222222',
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '150%',
    color: '#222222',
  },
});

export const PopupContainer = styled.div`
  @media ${breakpoints.mobile} {
    .img-icon {
      margin: 0 auto 24px;
      width: 100%;
    }

    .btn {
      width: 100%;
      height: 48px;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      margin-top: 24px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      max-width: 100%;
    }
  }
`;
